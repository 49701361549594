<template>
  <div class="play-card">
    <PlaybackControl :deviceId="deviceId" :accessToken="localAccessToken" :isPaused="isPaused" :currentTrack="currentTrack" :positionSong="songPosition"/>
  </div>
</template>

<script>
import axios from 'axios';
import querystring from 'querystring';
import PlaybackControl from "./PlaybackControl.vue";
import top2000 from './top_2000.json';

export default {
  components: { PlaybackControl },
  props: ['accessToken', 'refreshToken', 'spotifyClientId', 'spotifyClientSecret'],
  data() {
    return {
      socket: null,
      currentTrack: null,
      deviceId: null,
      isPaused: true,
      top2000: top2000,
      songPosition: 0,
      player: null,
      localAccessToken: this.accessToken // Local copy of accessToken
    };
  },
  mounted() {
    window.onSpotifyWebPlaybackSDKReady = () => {
      this.initializePlayer();
    };

    // Load the SDK script
    if (!window.Spotify) {
      const script = document.createElement('script');
      script.src = 'https://sdk.scdn.co/spotify-player.js';
      script.async = true;
      document.head.appendChild(script);
    }
  },
  methods: {
    async initializePlayer() {
      if (!this.localAccessToken) {
        this.localAccessToken = await this.refreshAccessToken();
      }

      this.player = new window.Spotify.Player({
        name: 'Jukebox',
        getOAuthToken: cb => { cb(this.localAccessToken); },
        volume: 0.5
      });

      // Error handling
      this.player.addListener('initialization_error', ({ message }) => { console.error("Initialization error:", message); });
      this.player.addListener('authentication_error', async ({ message }) => {
        console.error("Authentication error:", message);
        this.localAccessToken = await this.refreshAccessToken();
        this.player.getOAuthToken(cb => cb(this.localAccessToken));
      });
      this.player.addListener('account_error', ({ message }) => { console.error("Account error:", message); });
      this.player.addListener('playback_error', ({ message }) => { console.error("Playback error:", message); });

      // Playback status updates
      this.player.addListener('player_state_changed', state => {
        if (!state) return;
        this.isPaused = state.paused;
        this.currentTrack = state.track_window.current_track;
      });

      // Ready event
      this.player.addListener('ready', ({ device_id }) => {
        console.log('Ready with Device ID', device_id);
        this.deviceId = device_id;
      });

      const connected = await this.player.connect();
      if (!connected) {
        console.error("Failed to connect the Spotify player.");
      }
    },
    async refreshAccessToken() {
      try {
        const response = await axios.post('https://accounts.spotify.com/api/token', querystring.stringify({
          grant_type: 'refresh_token',
          refresh_token: this.refreshToken
        }), {
          headers: {
            'Authorization': 'Basic ' + Buffer.from(`${this.spotifyClientId}:${this.spotifyClientSecret}`).toString('base64'),
            'Content-Type': 'application/x-www-form-urlencoded',
          }
        });
        this.localAccessToken = response.data.access_token;
        return this.localAccessToken;
      } catch (error) {
        console.error('Error refreshing token:', error);
      }
    }
  }
};
</script>

<style>
.play-card {
  display: flex;
  justify-content: center;
}
</style>
