<template>
  <footer class="fullBottomBar">
<!--    <div class="link-list">-->
<!--      <router-link to="/teaching/" class="links-title">Teacher materials</router-link>-->
<!--      <router-link to="/teaching/pong" class="links">Pong</router-link>-->
<!--    </div>-->
    <div class="link-list">
      <router-link to="/web" class="links-title">Web</router-link>
      <router-link to="/web/stylemaker" class="links">Style Maker</router-link>
      <router-link to="/web/hitapp" class="links">Hit App</router-link>
    </div>
    <div class="link-list">
      <router-link to="/games" class="links-title">Games</router-link>
      <a href='https://store.steampowered.com/app/1573800/The_Steps_as_we_know_them/' target='_blank' class="links">The Steps as we know them</a>
      <a href='https://play.google.com/store/apps/details?id=com.TheProvisionGames.BlockGame&pli=1' target='_blank' class="links">Blocked In</a>
      <a href='https://the-provision-games.itch.io/dice-workshop' target='_blank' class="links">Dice Workshop (Demo)</a>
      <a href='https://the-provision-games.itch.io/soulful' target='_blank' class="links">Soulful (Demo)</a>
    </div>
    <div class="link-list">
      <h3 class="links-title">Legal</h3>
      <router-link to="/company/privacy-policy" class="links">Privacy policy</router-link>
      <router-link to="/company/terms-of-service" class="links">Terms of service</router-link>
    </div>
    <div>
      <p class="links" :style="{margin: '1.75vw 0 0 0'}">© 2024 Studio Idem. All Rights Reserved</p>
    </div>
  </footer>
</template>

<script>

</script>

<style>
.fullBottomBar {
  padding: 1rem 0 1rem 0;
  width: 100%;
  border-radius: 0 0 2vw 2vw;
  font-family: "Archivo Light";
  display: grid;
  grid-template-columns: 12vw 12vw 12vw 12vw;
  grid-gap: 2vw;
  justify-content: center;
  text-align: left;
}
h3{
  margin: 1.35vw 0 2vw 0;
}
p{
  font-family: "Questrial";
}
.link-list{
  display: flex;
  flex-direction: column;
}
.links{
  font-size: 1rem;
  font-family: "Questrial";
  margin: 0 0 1vw 0;
}
.links-title{
  margin: 1.35vw 0 2vw 0;
  font-weight: bold;
  font-size: 1.4rem;
  font-family: "Archivo SemiBold", sans-serif;
}

@media (max-width: 1000px){
  .fullBottomBar{
    grid-template-columns: 50vw;
    text-align: center;
  }
}

</style>