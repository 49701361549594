<script setup>

</script>

<template>
  <div class="legalPage">
    <div class="legalBackground">
      <h1 class="legalPageHeader">Privacy policy</h1>
      <p>
      Our website (Studio Idem) values your privacy and is committed to protecting it through our compliance with this policy. This policy applies to all information collected or submitted on our website.
      </p>
      <h1 class="legalHeader">1. Information we collect</h1>
      <p>
      We do not collect, save, or track any personal information about our users. We also do not use cookies or any other tracking technology on our website.

      We may collect anonymous usage statistics, such as the number of visitors to our website or the pages viewed, but this information is used solely for the purpose of improving our website and is not linked to any personal information.
      </p>
      <h1 class="legalHeader">2. Changes to this Policy</h1>
      <p>
      This privacy policy may be updated from time to time. We will notify you of any changes by updating this page. We encourage you to periodically review this policy to stay informed about how we are protecting your information. Your continued use of our website following the posting of changes to this policy will be deemed your acceptance of those changes.
      </p>
      <h1 class="legalHeader">3. Contact</h1>
      <p>
      If you have any questions or concerns about our privacy policy, please contact us by using our support page.
      </p>
    </div>
  </div>
</template>

<style scoped>

</style>