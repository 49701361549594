
<script>
import paletteData from "@/data/colorPalette.json"
import projectDataJS from "@/data/projectData.json";
import ProjectShow from "@/components/standardComponents/ProjectShow.vue";
import PageTitle from "@/components/standardComponents/PageTitle.vue";
export default {
  name: 'SupportPage',
  components: {PageTitle, ProjectShow},
  data(){
    return{
      projectDataJSON: projectDataJS,
      palette: paletteData.palette
    }
  }
}
</script>

<template>
  <PageTitle :page-title="'STUDIO IDEM'" :page-subtitle="'Web and game in one package'"/>
  <ProjectShow :project-data="projectDataJSON.frontPageProjects"/>
</template>

<style>
.homePage {
  width: calc(100% - 6rem);
  padding: 1rem 2rem 2rem 2rem;
  font-family: "Archivo Light";
  background-color: #eeeeee;
  border-radius: 0 0 1vw 1vw;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-top{
  width: 100%;
  height: 15rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.page-title{
  font-size: 3.5rem;
  margin: 0;
}
.page-subtitle{
  font-size: 1.7rem;
  margin: 0;
}
.pages{
  display: grid;
  grid-template-columns: 57% 57%;
  gap: 3vw;
  justify-content: center;
}
.aboutCompanyBox{
  margin: 1vw 0 1vw 0;
  border-radius: 1vw;
  background-color: white;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.subjectBox{
  margin: 2vw 0 2vw 0;
  border-radius: 1vw;
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.subjectTitle{
  margin: 2vw 0 1.5vw 0;
}
.subjectDescription{
  width: 75%;
  font-size: 1.5vw;
}
</style>