<template>
  <PageTitle :page-title="'SUPPORT'" :page-subtitle="`Support me via the following channels`"/>
<div class="supportPage">
  <div class="supportBox">
    <h2 class="supportTitle">Share my content</h2>
    <p class="supportParagraph">The cheapest way to support me is by sharing my content on social media. Helping me reach a broader audience will help me out a lot.</p>
    <div>
      <a class="socialMediaIconCircle" href='https://twitter.com/provision_games' target='_blank' :style="{backgroundColor: this.palette[1].hex}" >
        <img :src="require('@/assets/SocialMedia/TwitterLogo.png')" class="socialMediaIcon">
      </a>
    </div>
  </div>
  <div class="supportBox">
    <h2 :style="{margin: '0 0 1vw 0'}" class="supportTitle">Download and review my games</h2>
    <p :style="{margin: '0 0 1.5vw 0'}" class="supportParagraph">By downloading and reviewing my games, you do not only support me directly, you will also make my games more visible to a broader audience.</p>
    <router-link to="/games" class="supportButton" :style="{backgroundColor: this.palette[1].hex}">
      <h3 class="support-button-text">Buy a game</h3>
    </router-link>
  </div>
  <div class="supportBox">
    <h2 class="supportTitle">Donate money</h2>
    <p class="supportParagraph">You can support me directly via the platform Ko-Fi. This way I'll receive the most out of your donation without large fees.</p>
    <a href='https://ko-fi.com/W7W041R5X' target='_blank' class="supportButton" :style="{backgroundColor: this.palette[1].hex}">
      <h3 class="support-button-text">Buy me a coffee</h3>
    </a>
  </div>
</div>
</template>

<script>
import paletteData from "@/data/colorPalette.json"
import PageTitle from "@/components/standardComponents/PageTitle.vue";
export default {
  name: 'SupportPage',
  components: {PageTitle},
  data(){
    return{
      palette: paletteData.palette
    }
  }
}
</script>

<style>
.supportPage {
  width: calc(100% - 2rem);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1vw;
  justify-content: center;
  font-family: "Archivo Light";
  background-color: #eeeeee;
  border-radius: 0 0 1vw 1vw;
}
.supportBox{
  margin: 2vw 2vw 3vw 2vw;
  padding: 1rem;
  border-radius: 1vw;
  background-color: white;
  width: calc(100%- 2rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.supportTitle{
  font-size: 1.3rem;
  width: 80%;
  margin: 0 0 1.5vw 0;
}
.supportParagraph{
  width: 80%;
  margin: 0 0 3vw 0;
  font-size: 1rem;
}
.supportButton{
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  background-color: #b6e0b3;
  color: white;
  border-radius: 2rem;
}
.socialMediaIconCircle{
  margin-top: .5rem;
  height: 3.25rem;
  width: 3.25rem;
  background-color: #b6e0b3;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.socialMediaIcon{
  height: 1.75rem;
  width: 1.75rem;
}

.support-button-text{
  font-size: 1rem;
  margin: 0;
}


@media (max-width: 950px){
  .supportPage{
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .supportPage{
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>