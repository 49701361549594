<script>
export default {
  data() {
    return{
      base_url: 'http://146.190.238.121',
    }
  },
  methods: {
    login() {
      //When login pressed, go to the login on the server.
      window.location.href = `${this.base_url}:5001/auth/login`;
    }
  }
}
</script>

<template>
  <div>
    <button class="button-login" @click="login">Login with Spotify</button>
  </div>
</template>

<style scoped>
.button-login{
  width: 40vh;
  height: 80vh;
  background-color: black;
  font-size: 7.3vh;
}
</style>