<script>
export default {
  props: ['pageTitle', "pageSubtitle"],
}
</script>

<template>
  <div class="page-top">
    <h1 class="page-title">{{ pageTitle }}</h1>
    <h2 class="page-subtitle">{{ pageSubtitle }}</h2>
  </div>
</template>

<style>
.page-top{
  height: 15rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.page-title{
  font-size: 3.3rem;
  margin: 0;
}
.page-subtitle{
  font-size: 1.7rem;
  margin: 0;
}
</style>