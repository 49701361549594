<template>
  <header class="nav-holder">
    <div class="nav-links">
      <router-link to="/">
        <img class="logo" @click="showDropdown = false" v-bind:src="require('@/assets/Logo_Black_New.png')"  alt="company logo"/>
      </router-link>
      <button class="menu-toggle" @click="showDropdown = !showDropdown">
        ☰
      </button>
      <div v-if="showDropdown" class="dropdownMenu">
        <router-link to="/" class="nav-link-singular" @click="showDropdown = false">
          <h3 class="nav-bar-title">Home</h3>
        </router-link>
        <router-link to="/web" class="nav-link-singular" @click="showDropdown = false">
          <h3 class="nav-bar-title">Web</h3>
        </router-link>
        <router-link to="/games" class="nav-link-singular" @click="showDropdown = false">
          <h3 class="nav-bar-title">Games</h3>
        </router-link>
        <router-link to="/company" class="nav-link-singular" @click="showDropdown = false">
          <h3 class="nav-bar-title">About me</h3>
        </router-link>
      </div>
      <div class="navLinksAll" v-else>
        <router-link to="/" class="nav-link-singular">
          <h3 class="nav-bar-title">Home</h3>
        </router-link>
        <router-link to="/web" class="nav-link-singular">
          <h3 class="nav-bar-title">Web</h3>
        </router-link>
        <router-link to="/games" class="nav-link-singular">
          <h3 class="nav-bar-title">Games</h3>
        </router-link>
        <router-link to="/company" class="nav-link-singular">
          <h3 class="nav-bar-title">About me</h3>
        </router-link>
      </div>
    </div>
    <div class="indicatorArea">
      <div class="indicatorMargin">
        <div class="pageIndicator" :style="{backgroundColor: whichRoute === 'home' ? '#000000' : 'transparent'}"></div>
        <div class="pageIndicator" :style="{backgroundColor: whichRoute === 'web' ? '#000000' : 'transparent'}"></div>
        <div class="pageIndicator" :style="{backgroundColor: whichRoute === 'game' ? '#000000' : 'transparent'}"></div>
        <div class="pageIndicator" :style="{backgroundColor: whichRoute === 'company' ? '#000000' : 'transparent'}"></div>
      </div>
    </div>
  </header>
  <div class="nav-room">

  </div>
</template>

<script>
import paletteData from "@/data/colorPalette.json"
export default {
  name: 'ColorPicker',
  props: {
    change_route: Function
  },
  data(){
    return{
      whichRoute: 'home',
      palette: paletteData.palette,
      showDropdown: false,
    }
  },
  watch:{
    '$route.path'(value) {
      if (value === '/') {
        this.whichRoute = 'home';
      } else if (value.startsWith('/game')) {
        this.whichRoute = 'game';
      } else if (value.startsWith('/teaching')) {
        this.whichRoute = 'teaching';
      } else if (value.startsWith('/web')) {
        this.whichRoute = 'web';
      } else if (value.startsWith('/company')) {
        this.whichRoute = 'company';
        // } else if (value.startsWith('/support')) {
        //   this.whichRoute = 'support';
        // }
      } else {
        this.whichRoute = 'default';
      }
    }
  }
}
</script>

<style>
.nav-holder {
  width: 100%;
  position: fixed;
  z-index: 99;
  background-color: white;
}
.nav-links {
  height: 3.5rem;
  display: flex;
  justify-content: space-between;
  border-radius: 2vw 2vw 0 0;
  font-family: "Archivo Light";
}
.logo {
  height: 3.5rem;
  margin: .5rem .5rem .5rem 2rem;
}
.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 3rem;
  height: 4.75rem;
  width: 4.5rem;
  margin: -.25rem 0 0 0;
  cursor: pointer;
  color: black;
}
.navLinksAll {
  display: flex;
  justify-content: flex-end;
  align-items: end;
  margin-right: 2.8rem;
}
.nav-link-singular {
  height: 83%;
  width: 17.5vw;
  color: white;
  border-radius: 1vw 1vw 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageIndicator {
  height: .3rem;
  width: 11.5vw;
  margin: 0 3vw;
  background-color: #b5e3a6;
}
.indicatorMargin {
  display: flex;
  margin-right: 1.8rem;
}
.indicatorArea {
  margin-left: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: start;
  height: 1vw;
  width: calc(100% - 2rem);
}
.dropdownMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 4rem;
  background-color: white;
  padding: 2rem 0 0 0;
  border-radius: 5px;
  width: 100%;
  height: 20rem;
  z-index: 999;
}
.nav-bar-title {
  font-size: 1.6rem;
  margin: 0;
  color: black;
}
.nav-room {
  height: 4rem;
}

/* Media Query for Mobile View */
@media (max-width: 1000px) {
  .nav-holder{
    height: 4.5rem;
  }
  .navLinksAll {
    display: none;
  }
  .menu-toggle {
    display: block;
  }
  .logo {
    height: 3.5rem;
    margin: .5rem .3rem 0 1.5rem;
  }
  .nav-link-singular {
    width: 100%;
    height: 4.5rem;
    font-weight: bold;
    color: black;
  }
  .pageIndicator {
    display: none;
  }
  .indicatorArea {
    margin-top: 3rem;
  }
  .nav-bar-title{
    font-size: 2rem;
  }
}


</style>