<template>
  <PageTitle :page-title="'GAMES'" :page-subtitle="'Mostly work in progress'"/>
  <ProjectShow :project-data="projectDataJSON.gameProjects"/>
</template>

<script>
import projectDataJS from "@/data/projectData.json"
import ProjectShow from "@/components/standardComponents/ProjectShow.vue";
import PageTitle from "@/components/standardComponents/PageTitle.vue";
export default {
  components: {PageTitle, ProjectShow},
  data(){
    return{
      projectDataJSON: projectDataJS,
    }
  },
}
</script>

