<template>
  <PageTitle :page-title="'WEB'" :page-subtitle="`Tools and personal projects`"/>
  <ProjectShow :project-data="projectDataJSON.webProjects"/>
</template>

<script>
import projectDataJS from "@/data/projectData.json"
import ProjectShow from "@/components/standardComponents/ProjectShow.vue";
import PageTitle from "@/components/standardComponents/PageTitle.vue";
export default {
  components: {PageTitle, ProjectShow},
  data(){
    return{
      projectDataJSON: projectDataJS,
    }
  }
}
</script>