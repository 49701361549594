<script>
import axios from "axios";
import top2000 from "./top_2000.json";

export default {
  name: "PlaybackControl",
  props: [
    'deviceId',
    'accessToken',
    'isPaused',
    'positionSong',
  ],
  data(){
    return {
      turnedAround: false,
      top2000: top2000,
      songPosition: 0,
      currentTrack: null,
    }
  },
  methods: {

    async playPause() {
      if (!this.deviceId) return;
      if(this.isPaused){
        try {
          await axios.put(
              `https://api.spotify.com/v1/me/player/play?device_id=${this.deviceId}`,
              {},
              {headers: {Authorization: `Bearer ${this.accessToken}`}}
          );
        } catch (error) {
          console.error('Error playing track:', error);
        }
      }else{
        try {
          await axios.put(
              `https://api.spotify.com/v1/me/player/pause?device_id=${this.deviceId}`,
              {},
              {headers: {Authorization: `Bearer ${this.accessToken}`}}
          );
        } catch (error) {
          console.error('Error pausing track:', error);
        }
      }

    },
    async next() {
      if (!this.deviceId) return;

      try {
        await axios.post(
            `https://api.spotify.com/v1/me/player/next?device_id=${this.deviceId}`,
            {},
            {headers: {Authorization: `Bearer ${this.accessToken}`}}
        );
      } catch (error) {
        console.error('Error skipping track:', error.response ? error.response.data : error.message);
      }
    },
    async prev() {
      if (!this.deviceId) return;

      try {
        await axios.post(
            `https://api.spotify.com/v1/me/player/previous?device_id=${this.deviceId}`,
            {},
            {headers: {Authorization: `Bearer ${this.accessToken}`}}
        );
      } catch (error) {
        console.error('Error skipping track:', error.response ? error.response.data : error.message);
      }
    },
    async getTrackId(title, artist) {
      try {
        const response = await axios.get(`https://api.spotify.com/v1/search`, {
          headers: { Authorization: `Bearer ${this.accessToken}` },
          params: { q: `track:${title} artist:${artist}`, type: 'track' }
        });
        if (response.data.tracks.items.length > 0) {
          this.currentTrack = response.data.tracks.items[0];
          return response.data.tracks.items[0].id; // Return the first matching track ID
        }
      } catch (error) {
        console.error("Error fetching track ID:", error);
      }
      return null; // Return null if not found
    },
    async playSongByPosition(position) {
      const song = this.top2000.find(s => s.positie === position);
      if (song) {
        const trackId = await this.getTrackId(song.titel, song.artiest);
        if (trackId) {
          // Play the track using the PlaybackControl method
          try {
            await axios.put(
                `https://api.spotify.com/v1/me/player/play?device_id=${this.deviceId}`,
                { uris: [`spotify:track:${trackId}`] },
                { headers: { Authorization: `Bearer ${this.accessToken}` } }
            );
          } catch (error) {
            console.error('Error playing track:', error);
          }
        } else {
          console.error("Track ID not found for:", song);
        }
      } else {
        console.error("Song not found for position:", position);
      }
    },
    playRandomSong() {
      this.turnedAround = false;
      const randomIndex = Math.floor(Math.random() * this.top2000.length);
      const randomSong = this.top2000[randomIndex];
      this.playSongByPosition(randomSong.positie);
      this.songPosition = randomSong.positie;
    },
  }
}
</script>

<template>
  <div class="track-card">
    <img alt="rotate card" src="../components/icons/turn.svg" class="rotate-image" @click="turnedAround = !turnedAround">
    <div v-if="turnedAround" class="current-track">
      <h1 class="postion-number">Nr. {{songPosition}}</h1>
      <div class="track-box">
        <img class="current-track-image" v-if="currentTrack" :src="currentTrack.album.images[0].url" :alt="'album image from: ' + currentTrack.name">
        <div class="track-text">
          <h3 v-if="currentTrack" class="current-track-info">{{ currentTrack.name }}</h3>
          <br>
          <h2 v-if="currentTrack" class="current-track-info artist"> {{ currentTrack.artists[0].name }}</h2>
        </div>
      </div>
    </div>
    <div v-else class="current-track">
      <div class="hidden-track">
        <h1 class="game-title">Top 2000</h1>
        <h2 class="game-title">Hits guessing game</h2>
      </div>
    </div>
    <div class="buttons">
      <button v-if="isPaused" @click="playPause" class="buttons-specific play-button">Play</button>
      <button v-if="!isPaused" @click="playPause" class="buttons-specific play-button">Pause</button>
      <button @click="playRandomSong" class="buttons-specific random-button">New Song</button>
    </div>
  </div>
</template>

<style scoped>
.track-card{
  width: 40vh;
  height: 80vh;
  max-width: 100vw;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: #17331b;
  border-radius: 4vh;
  border: 1vh solid #000000;
  color: black;
  font-family: Noto Sans Medefaidrin;
}
.current-track{
  height: 75%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #b1cfa6;
}
.postion-number{
  text-align: center;
  font-size: 6vh;
  margin: 1vh;
  color: #17331b;
}
.rotate-image{
  height: 7vh;
  margin: 2vh;
}
.track-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 100%;
  max-height: 80vh;
}
.current-track-image{
  margin: 0 4vh 0 4vh;
  width: 70%;
  max-width: 35vh;
  max-height: 35vh;
 }
.current-track-info {
  width: 100%;
  max-height: 100%;
  font-weight: normal;
  color: #17331b;
  font-size: 3vh;
  max-font-size: 2vw;
  text-align: center;
}
.track-text{
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 0 4vh 0 4vh;
}
.artist{
  font-size: 4vh;
  max-font-size: 1vw;
  font-weight: bold;
}
.buttons{
  display: flex;
  margin: 2vh 0 2vh 0;
  width: 100%;
  justify-content: center;
  display: flex;
}
.buttons-specific{
  width: 47%;
  height: 10vh;
  font-size: 3vh;
  background-color: #b1cfa6;
  font-family: Noto Sans Medefaidrin;
  font-weight: bold;
  color: #17331b;
  padding: 0 1vh 0 1vh;
  border: .3vh solid #17331b;
}
.hidden-track{
  height: 100%;
  width: 100%;
  border-radius: 3.25vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.game-title{
  font-weight: bold;
  color: #17331b;
  margin: 2vh 3vh;
}
.play-button{
  border-radius: 2vh 0 0 2vh;
}
.random-button{
  border-radius: 0 2vh 2vh 0;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
h1{
  font-size: 7.3vh;
}
h2{
  font-size: 5vh;
  margin: 0;
}
h3{
  font-size: 4vh;
  margin: 0;
}
</style>