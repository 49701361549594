<template>
  <div class="totalArea">
      <div class="showPalette">
        <div v-for="color in colorPalette" :key="color">
          <div class="colorBox" :style="{ 'background-color': color.color}">
            <p v-if="Number(color.type) >= 165" class="colorInfo" :style="{ 'color': '#000000' }">{{ color.color }}</p>
            <p v-else class="colorInfo" >{{ color.color }}</p>
          </div>
        </div>
      </div>
    <div class="showFonts">
      <div class="showFontsText">
        <h3>Title font:</h3>
        <p>{{ chosenFonts[0] }}</p>
      </div>
      <div class="showFontsText">
        <h3>Paragraph font:</h3>
        <p>{{ chosenFonts[1] }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailArea',
  props: {
    colorPalette: {},
    chosenFonts: Array,
  },
}
</script>

<style>
.totalArea{
  width: 90.2%;
  height: 23vw;
  background-color: white;
  border-radius: 1vw;
  padding-top: 1.5vw;
}
.showPalette{
  width: 100%;
  display: flex;
  padding-bottom: .5vw;
  justify-content: space-evenly;
}
.colorBox{
  border-radius: 1vw;
  width: 5vw;
  height: 5vw;
  border:  none;
  padding: .5vw;
  color: white;
  display: flex;
  vertical-align: center;
  justify-content: center;
}
.colorInfo{
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.showFonts{
  display: grid;
  gap: 10%;
  grid-template-columns: 45% 45%;
}
.showFontsText{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
</style>