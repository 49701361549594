<template>
  <div class="gamesScreen" :style="{height: '28vw'}">
  </div>
</template>

<script>
export default {
  data(){
    return{
    }
  },
  mounted() {
  }
}
</script>

<style>

</style>