<template>
  <div class="wholeCard">
    <div class="textChangeCard">
      <h2 class="titleCell">Title</h2>
      <select v-model="categoryChosen">
        <option v-for="(category, key) in categories" :value="category" :key="key">
          {{category}}
        </option>
      </select>
      <button @click="FetchFonts(true)">New Font</button>
      <input v-model="inputText[0]">
      <div>
        <button class="decreaseButton" @click="changeFontSize(false, 0)">-</button>
        <button class="increaseButton" @click="changeFontSize(true, 0)">+</button>
      </div>
    </div>
    <div class="textChangeCardSlogan">
      <h2 class="titleCell" :style="{margin: '.6vw'}">Slogan</h2>
      <input class="sloganContent" v-model="inputText[1]">
      <div class="sloganContent">
        <button class="decreaseButton" @click="changeFontSize(false, 1)">-</button>
        <button class="increaseButton" @click="changeFontSize(true, 1)">+</button>
      </div>
    </div>
    <div class="textChangeCard">
      <h2 class="titleCell">Paragraph</h2>
        <select v-model="categoryChosen">
        <option v-for="(category, key) in categories" :value="category" :key="key">
          {{category}}
        </option>
      </select>
      <button @click="FetchFonts(false)">New Font</button>
      <input v-model="inputText[2]">
      <div>
        <button class="decreaseButton" @click="changeFontSize(false, 2)">-</button>
        <button class="increaseButton" @click="changeFontSize(true, 2)">+</button>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'GetFonts',
  data() {
    return {
      apiKey: 'AIzaSyDEU4fqm0RHjLChm-oTt31f118s552It-8',
      fontsList: {},
      specificFont: [],
      categories: {
        "cat 1": 'sans-serif',
        "cat 2": 'serif',
        "cat 3": 'handwriting',
        "cat 4": 'display'},
      categoryChosen: "sans-serif",
      fontsChosen: ["Archivo", "Questrial"],
      inputText: ["Title", "Subtitle", "Description"],
      inputSize: [2.25, 2, 1.25]
    }
  },
  props: {
    send_font_to_parent: Function
  },
  methods: {
    FetchFonts(titleChosen){
      fetch(`https://www.googleapis.com/webfonts/v1/webfonts?key=${this.apiKey}`)
          .then((response) => response.json())
          .then((data) => {
            this.fontsList = data.items
            this.specificFont.splice(0)
            this.fontsList.forEach(fontsChosen=>{
              if (fontsChosen.category == this.categoryChosen) {
                this.specificFont.push(fontsChosen)
              }
            })
          })
          .then(() => {
            this.changeFont(titleChosen)
            this.sendFontToApp()
          })
    },
    changeFont(titleChosen) {
      const selectedFontIndex = Math.floor(Math.random() * this.specificFont.length);
      if(titleChosen) {
        this.fontsChosen[0] = this.specificFont[selectedFontIndex].family;
      }else {
        this.fontsChosen[1] = this.specificFont[selectedFontIndex].family;
      }
      this.loadGoogleFont(this.fontsChosen[0], this.fontsChosen[1]);
    },
    loadGoogleFont(titleFontFamily, paragraphFontFamily){
      const linkTitle = document.createElement('link');
      linkTitle.href = `https://fonts.googleapis.com/css?family=${titleFontFamily.replace(/\s/g, '+')}`;
      linkTitle.rel = 'stylesheet';
      const linkPar = document.createElement('link');
      linkPar.href = `https://fonts.googleapis.com/css?family=${paragraphFontFamily.replace(/\s/g, '+')}`;
      linkPar.rel = 'stylesheet';
      document.head.appendChild(linkPar);
      document.head.appendChild(linkTitle);
    },
    changeFontSize(increaseFontSize, whichText) {
      if(increaseFontSize){
        this.inputSize[whichText] = this.inputSize[whichText] + .25
      }
      else{
        this.inputSize[whichText] = this.inputSize[whichText] - .25
      }
      this.sendFontToApp()
    },
    sendFontToApp() {
      this.send_font_to_parent(this.fontsChosen, this.inputText, this.inputSize, this.fontsChosen)
    },
  },
  mounted() {
    this.FetchFonts(true)
    this.FetchFonts(false)
  },
}
</script>


<style>
.wholeCard{
  width: 89%;
  margin: 1.5vw 0 1.5vw 0;
  display: grid;
  gap: 1.5vw;
  justify-content: center;
  align-content: center;
  grid-template-columns: 36% 25% 36%;
}
.textChangeCard{
  display: grid;
  gap: .5vw;
  grid-template-columns: 7.5vw 7.5vw;
  background-color: white;
  border-radius: 1vw;
  align-items: center;
  justify-content: center;
  padding: .75vw 0 1.5vw 0;
}
.textChangeCardSlogan{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: .75vw 1vw 1vw 1vw;
  background-color: white;
  border-radius: 1vw;
}
.sloganContent{
  margin: .3vw;
  width: 7.5vw;
}
.titleCell{
  grid-column-start: 1;
  grid-column-end: 3;
  margin:.5vw;
}
.decreaseButton{
  font-size: 1.5vw;
  width: 3.75vw;
  border-radius: 2vw 0 0 2vw;
}
.increaseButton{
  font-size: 1.5vw;
  width: 3.75vw;
  border-radius: 0 2vw 2vw 0;
}
</style>