<script setup>

</script>

<template>
  <h1 class="pageTitle">404: Not Found</h1>
  <div class="standardwhiteback">
  </div>
</template>

<style>
.standardwhiteback{
  height: 40vw;
  background-color: #eeeeee;
  width: 95%;
  border-radius: 0 0 1vw 1vw;
  font-family: "Archivo SemiBold";
}
</style>