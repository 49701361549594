<script>
export default {
  props: ['projectData'],
}
</script>

<template>
  <div class="projectScreen">
    <a v-for="projects in projectData" :key="projects.title"  class="showProjectBlock" :href=projects.url target="_blank" @mouseover="projects.showData = true" @mouseleave="projects.showData = false">
      <img class="projectImage" :src="projects.image" :alt="'profile picture for' + projects.title" />
      <div class="projectTextBelow">
        <h2 class="projectTitle">{{ projects.title }}</h2>
        <p class="projectDescription">{{ projects.text }}</p>
      </div>
    </a>
  </div>
</template>

<style>
.projectScreen{
  width: calc(100vw - 20vw);
  padding: 5rem 10vw 4rem 10vw;
  font-family: "Archivo Light";
  color: black;
  display: grid;
  height: auto;
  background-color: #eeeeee;
  gap: 10rem;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-evenly;
}

.showProjectBlock{
  width: 100%;
  height: 100%;
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  position: relative;
}
.projectTextBelow{
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  text-align: left;
  padding: 1vh 0 1vh 0;
}
.projectTitle{
  margin: 1vw 0 .5vw 1vw;
  font-size: 2rem;
}
.projectDescription{
  width: 90%;
  margin: 0 0 0 1vw;
  font-size: 1.4rem;
}
.projectImage{
  width: 100%;
  aspect-ratio: 11/9;
  border-radius: 1vw;
  object-fit: cover;
}
@media (max-width: 1000px) {
  .projectScreen{
    width: calc(100vw - 30vw);
    padding: 5rem 15vw 4rem 15vw;
    grid-template-columns: repeat(1, 1fr);
  }
}


@media (max-width: 600px) {
  .projectScreen{
    width: calc(100vw - 20vw);
    padding: 5rem 10vw 4rem 10vw;
    grid-template-columns: repeat(1, 1fr);
  }
}

</style>