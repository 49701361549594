<template>
  <div class="cookieModal">
    <div class="modalContent">
      <div class="cookieCloseTitle">
        <h1 class="cookieTitle">Cookies</h1>
        <div class="close" @click="closeCookies">&times;</div>
      </div>
      <p class="cookieText">This website uses cookies to make sure the website can run smoothly.</p>
      <p class="cookieText">We dont use third party cookies, only the essential for our website.</p>
      <div class="cookieButtonHolder">
        <button class="cookieButton" @click="acceptCookies">Accept</button>
        <button class="cookieButton" @click="declineCookies">Decline</button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    methods: {
      closeCookies() {
        this.$emit("closeCookies");
      },
      acceptCookies() {
        this.$emit("acceptCookies");
        this.$emit("closeCookies");
      },
      declineCookies() {
        this.$emit("declineCookies");
        this.$emit("closeCookies");
      },
    },
  };
</script>

<style>
.cookieModal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}
.modalContent {
  background-color: #fefefe;
  border: 1px solid #888;
  width: 40vw;
  height: 20vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1vw;
  font-family: "Questrial";
}
.cookieCloseTitle{
  display: grid;
  width: 100%;
  grid-template-columns: 90% 10%;

}
.cookieTitle{
  font-family: 'Archivo Light';
  margin: 2vw 0 2vw 4vw;
}
.close {
  color: #aaaaaa;
  font-size: 4vw;
  font-weight: bold;
  margin: 0 1vw 0 0;
}
.close:hover,
.close:focus {
  color: black;
  cursor: pointer;
}
.cookieText{
  font-size: 1.5vw;
  width: 75%;
  align-self: center;
  margin: 0 0 1vw 0;
}
.cookieButtonHolder{
  margin: 1vw 0 0 0;
  width: 50%;
  display: flex;
  justify-content: space-between;
}
.cookieButton{
  background-color: #aaaaaa;
}
</style>