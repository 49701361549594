<template>
  <div class="colorPicker">
    <div class="hsvPicker">
      <div class="hsvCircle" :style="{ background: hsvStyle }" @click="clickOnHue">
        <div class="emptyCircle">
          <div class="circleIndicator">
            <div class="indicatorRotated"></div>
          </div>
        </div>
      </div>
      <div class="hsvLine" :style="{ background: saturationStyle }" @click="clickOnSaturation">
        <div class="indicator" :style="{ top: indicatorPositionSaturation }"></div>
      </div>
      <div class="hsvLine" :style="{ background: valueStyle }" @click="clickOnValue">
        <div class="indicator" :style="{ top: indicatorPositionValue }"></div>
      </div>
    </div>
    <div class="switchPaletteArea">
      <select class="options" v-model="paletteChosen" @change="sendColorToApp">
        <option v-for="(palette, key) in palettes" :value="palette" :key="key">
          {{ palette }}
        </option>
      </select>
      <button @click="shuffleArray">Shuffle colors</button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ColorPicker',
  data(){
    return{
      mainColor:[
        {name: 'red', amount: 0, rgb: 0, hex: ''},
        {name: 'green', amount: 0, rgb: 0, hex: ''},
        {name: 'blue', amount: 0, rgb: 0, hex: ''},
      ],
      palettes: {
        "palette 1": 'analogous',
        "palette 2": 'monochromatic',
        "palette 3": 'colorful'
      },
      paletteChosen: 'analogous',
      hexMainColor: ``,
      mainColorHSV: { 'hue': 0, 'saturation': 0, 'value': 0 },
      monochromaticList: [{'color': '', 'type': ''},{'color': '', 'type': ''},{'color': '', 'type': ''},{'color': '', 'type': ''},{'color': '', 'type': ''}],
      analogousList: [{'color': '', 'type': ''},{'color': '', 'type': ''},{'color': '', 'type': ''},{'color': '', 'type': ''},{'color': '', 'type': ''}],
      colorfulList: [{'color': '', 'type': ''},{'color': '', 'type': ''},{'color': '', 'type': ''},{'color': '', 'type': ''},{'color': '', 'type': ''}],
      hsvStyle: "",
      saturationStyle: "",
      valueStyle: "",
      hsvPoint: 260,
      saturationPoint: .25,
      valuePoint: .90,
      indicatorPositionHue: "260deg",
      indicatorPositionSaturation: "75%",
      indicatorPositionValue: "10%",
      hueCirclePosX: 0,
      hueCirclePosY: 0,
    }
  },
  props: {
    send_color_to_parent: Function
  },
  methods : {
    dynamicStyle() {
      //Translate decimal number to hexadecimal for the hex code
      this.mainColor.forEach(color=>{
            color.hex = `${(color.rgb).toString(16)}`
            if(color.hex.length < 2){
                color.hex = `0${color.hex}`
            }
      })
      this.hexMainColor = `#${this.mainColor[0].hex}${this.mainColor[1].hex}${this.mainColor[2].hex}`
      this.mainColorHSV = this.rgbToHSV(this.mainColor[0].rgb,this.mainColor[1].rgb,this.mainColor[2].rgb)
      this.mainToMonochromatic()
      this.mainToAnalogous()
      this.mainToColorful()
      this.sendColorToApp()
    },
    rgbToHSV(red, green, blue){
      red = (red / 255)
      green = (green / 255)
      blue = (blue / 255)
      let max = Math.max(red, green, blue)
      let min = Math.min(red, green, blue)
      let delta = max - min
      let hue, saturation, value;

      if (delta === 0) {
        hue = 0
      }else if (max === red){
        hue = (((green - blue) / delta) % 6)
      }else if (max === green){
        hue = (((blue - red) / delta) + 2)
      }else{
        hue = (((red - green) / delta) + 4)
      }

      hue = Math.round(hue * 60)
      if(hue < 0){
        hue += 360
      }

      if(max === 0){
        saturation = 0
      }else{
        saturation = (delta/max)
      }

      value = max;

      return {hue, saturation, value}
    },
    hsvToRGB(hue, saturation, value){
      let chroma = value * saturation
      let huePrime = hue / 60
      let x = chroma * (1 - Math.abs(huePrime % 2 - 1))
      let red, green, blue

      if(huePrime >= 0 && huePrime <= 1) {
        red = chroma
        green = x
        blue = 0
      }else if(huePrime <= 2){
        red = x
        green = chroma
        blue = 0
      }else if(huePrime <= 3){
        red = 0
        green = chroma
        blue = x
      }else if(huePrime <= 4){
        red = 0
        green = x
        blue = chroma
      }else if(huePrime <= 5){
        red = x
        green = 0
        blue = chroma
      }else{
        red = chroma
        green = 0
        blue = x
      }

      let min = value - chroma;

      red = Math.round((red + min) * 255)
      green = Math.round((green + min) * 255)
      blue = Math.round((blue + min) * 255)

      return {red: red, green: green, blue: blue}
    },
    rgbToHEX(red, green, blue){
      let rgb = [{color: red, hex: ''},{color: green, hex: ''},{color: blue, hex: ''}]
      rgb.forEach(rgbValues=>{
        rgbValues.hex = `${(rgbValues.color).toString(16)}`
        if(rgbValues.hex.length < 2) {
          rgbValues.hex = `0${rgbValues.hex}`
        }
      })
      return `#${rgb[0].hex}${rgb[1].hex}${rgb[2].hex}`
    },
    mainToMonochromatic() {
      let rgb = this.mainColorHSV
      let hue = this.mainColorHSV.hue
      let saturation = this.mainColorHSV.saturation
      let value = this.mainColorHSV.value

      for (let i = 0; i < 5; i++) {
        rgb = this.hsvToRGB(hue, saturation, value)
        this.monochromaticList[i].color = this.rgbToHEX(rgb.red, rgb.green, rgb.blue)
        this.monochromaticList[i].type = this.lightOrDark(rgb.red, rgb.green, rgb.blue)
        value = value + .2
        if (value > 1) {
          value = value - 1
        }
      }
      this.monochromaticList.sort((a,b) => (a.type > b.type) ? -1: 1)
    },
    mainToAnalogous() {
      let rgb
      let hue = this.mainColorHSV.hue
      let saturation = this.mainColorHSV.saturation
      let value = this.mainColorHSV.value


      rgb = this.hsvToRGB(hue, saturation, value)
      this.analogousList[2].color = this.rgbToHEX(rgb.red, rgb.green, rgb.blue)
      this.analogousList[2].type = this.lightOrDark(rgb.red, rgb.green, rgb.blue)

      hue = hue - 12
      if(hue < 0){
        hue = hue + 360
      }
      let tempSaturation = .20
      rgb = this.hsvToRGB(hue, tempSaturation, value)
      this.analogousList[0].color = this.rgbToHEX(rgb.red, rgb.green, rgb.blue)
      this.analogousList[0].type = this.lightOrDark(rgb.red, rgb.green, rgb.blue)

      rgb = this.hsvToRGB(hue, saturation, value)
      this.analogousList[1].color = this.rgbToHEX(rgb.red, rgb.green, rgb.blue)
      this.analogousList[1].type = this.lightOrDark(rgb.red, rgb.green, rgb.blue)

      hue = hue + 24
      if(hue > 360){
        hue = hue - 360
      }
      rgb = this.hsvToRGB(hue, saturation, value)
      this.analogousList[3].color = this.rgbToHEX(rgb.red, rgb.green, rgb.blue)
      this.analogousList[3].type = this.lightOrDark(rgb.red, rgb.green, rgb.blue)

      value = .20
      if(value < 0){
        value = value + 1
      }
      rgb = this.hsvToRGB(hue, saturation, value)
      this.analogousList[4].color = this.rgbToHEX(rgb.red, rgb.green, rgb.blue)
      this.analogousList[4].type = this.lightOrDark(rgb.red, rgb.green, rgb.blue)

      this.analogousList.sort((a,b) => (a.type > b.type) ? -1: 1)
    },
    mainToColorful(){
      let rgb = this.mainColorHSV
      let hue = this.mainColorHSV.hue
      let saturation = this.mainColorHSV.saturation
      let value = this.mainColorHSV.value

      for (let i = 0; i < 5; i++) {
        rgb = this.hsvToRGB(hue, saturation, value)
        this.colorfulList[i].color = this.rgbToHEX(rgb.red, rgb.green, rgb.blue)
        this.colorfulList[i].type = this.lightOrDark(rgb.red, rgb.green, rgb.blue)
        hue = hue + 72
        if (hue > 360) {
          hue = hue - 360
        }
      }
      this.colorfulList.sort((a,b) => (a.type > b.type) ? -1: 1)
    },
    sendColorToApp() {
      let paletteChosen = []
      switch (this.paletteChosen){
        case "monochromatic":
          paletteChosen = this.monochromaticList.map(color => color)
          break;
        case "analogous":
          paletteChosen = this.analogousList.map(color => color)
          break;
        case "colorful":
          paletteChosen = this.colorfulList.map(color => color)
          break;
      }
      this.send_color_to_parent(this.hexMainColor, paletteChosen)
    },
    generateHue() {
      const gradientColors = Array.from({ length: 360 }, (_, index) => {
        let rgb = this.hsvToRGB(index, this.saturationPoint, this.valuePoint);
        return `rgb(${rgb.red}, ${rgb.green}, ${rgb.blue})`
      })
      this.hsvStyle = `conic-gradient(from 0deg at 50% 50%, ${gradientColors.join(", ")})`
    },
    generateSaturation() {
      const saturationColors = Array.from({ length: 100 }, (_, index) => {
        let rgb = this.hsvToRGB(this.hsvPoint, 1-(index/100), this.valuePoint)
        return `rgb(${rgb.red}, ${rgb.green}, ${rgb.blue}) ${index}%`
      });
      this.saturationStyle = `linear-gradient(to bottom, ${saturationColors.join(", ")})`
    },
    generateValue() {
      const valueColors = Array.from({ length: 100 }, (_, index) => {
        let rgb = this.hsvToRGB(this.hsvPoint, this.saturationPoint, 1-(index/100));
        return `rgb(${rgb.red}, ${rgb.green}, ${rgb.blue})`;
      })
      this.valueStyle = `linear-gradient(to bottom, ${valueColors.join(", ")})`
    },
    clickOnHue(event){
      const circle = event.currentTarget.getBoundingClientRect()
      const centerX = circle.left + circle.width/2
      const centerY = circle.top + circle.height/2
      const posX = event.clientX - centerX
      const posY = - (event.clientY - centerY)
      let angle = Math.atan2(posX, posY) * (180 / Math.PI)
      if (angle < 0){
        angle = angle + 360
      }
      this.indicatorPositionHue = angle + 'deg'
      this.hueCirclePosX = 160 * Math.sin(angle * (Math.PI / 180)) + '%';
      this.hueCirclePosY = -690 * Math.cos(angle * (Math.PI / 180)) + '%';
      this.hsvPoint = angle;
      this.recalculateHSV()
    },
    clickOnSaturation(event){
      const barHeight = event.target.clientHeight
      const clickPosition = (event.offsetY / barHeight) * 100
      this.indicatorPositionSaturation = `${clickPosition}%`
      this.saturationPoint = 1 - (clickPosition / 100)
      this.recalculateHSV()
    },
    clickOnValue(event){
      const barHeight = event.target.clientHeight
      const clickPosition = (event.offsetY / barHeight) * 100
      this.indicatorPositionValue = `${clickPosition}%`
      this.valuePoint = 1 - (clickPosition / 100)
      this.recalculateHSV()
    },
    recalculateHSV(){
      let hue = this.hsvPoint
      let saturation = this.saturationPoint
      let value = this.valuePoint
      let rgb = this.hsvToRGB(hue, saturation, value)
      this.mainColor[0].rgb = rgb.red
      this.mainColor[1].rgb = rgb.green
      this.mainColor[2].rgb = rgb.blue
      this.dynamicStyle()
      this.generateHue()
      this.generateSaturation()
      this.generateValue()
    },
    lightOrDark(red, green, blue){
      return Math.sqrt(0.299 * (red * red) + 0.587 * (green * green) + 0.114 * (blue * blue))
    },
    shuffleArray: function () {
      for(let i = 3 ; i >= 0; i--){
        const j = Math.floor(Math.random() * (i + 1));
        [this.monochromaticList[i], this.monochromaticList[j]] = [this.monochromaticList[j], this.monochromaticList[i]];
        [this.analogousList[i], this.analogousList[j]] = [this.analogousList[j], this.analogousList[i]];
        this.sendColorToApp()
      }
    }
  },
  mounted() {
    this.generateHue()
    this.generateSaturation()
    this.generateValue()
    this.recalculateHSV()
    this.dynamicStyle()
  },
}
</script>

<style>
.colorPicker{
  width: 58.75%;
  color: black;
  padding: 1vw 1vw 1vw 1vw;
  display: grid;
  align-content: center;
  background-color: white;
  border-radius: 1vw;
  grid-template-columns: 70% 30%;
}
.hsvPicker{
  width: 90%;
  display: flex;
  justify-content: space-evenly;
}
.hsvLine {
  border-radius: 5px;
  height: 10vw;
  width: 2vw;
  position: relative;
}
.hsvLine:hover{
  cursor: pointer;
}
.hsvCircle {
  height: 10vw;
  width: 10vw;
  position: relative;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}
.hsvCircle:hover{
  cursor: pointer;
}
.indicator {
  position: absolute;
  top: 0;
  width: 2.2vw;
  height: .5vw;
  background-color: black;
  pointer-events: none;
  transform: translate(-5%, -50%);
}
.circleIndicator{
  position: absolute;
  vertical-align: middle;
  height: 10vw;
  background-color: transparent;
  pointer-events: none;
  transform: translateY(-12.5%) rotate(v-bind(indicatorPositionHue));
}
.indicatorRotated{
  position: absolute;
  top: 0;
  width: .5vw;
  height: 2vw;
  background-color: black;
  pointer-events: none;
}
.emptyCircle{
  justify-content: center;
  transform: translateY(17%);
  position: absolute;
  background-color: black;
  border-radius: 50%;
  width: 7.5vw;
  height: 7.5vw;
  vertical-align: middle;
  display: flex;
}
.options{
  border-radius: 5px;
  height: 3vw;
  width: 9vw;
  text-align: center;
}
.switchPaletteArea{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 2.5vw;
}
</style>