<script >
export default {
  name: 'ExampleArea',
  props: {
    colorPaletteFull: Array,
    chosenFonts: Array,
    inputText: Array,
    inputSize: Array,
  }
}
</script>

<template>
  <div class="wholePage">
    <div class="bars" :style="{'backgroundColor': colorPaletteFull[4].color}">
    </div>
    <div class="headArea" :style="{'backgroundColor': colorPaletteFull[1].color}">
      <div :style="[Number(colorPaletteFull[1].type)>=165 ? {color: 'black'} : {color: 'white'}]">
        <h1 class="bigTitle" >{{ inputText[0] }}</h1>
        <h2 class="smallTitle">{{ inputText[1] }}</h2>
      </div>
    </div>
    <div class="descriptionArea" :style="{'backgroundColor': colorPaletteFull[0].color}">
      <p v-if="Number(colorPaletteFull[0].type) >= 165" class="smallText" :style="{ 'color': '#000000' }">{{ inputText[2] }}</p>
      <p v-else class="smallText" :style="{ 'color': '#ffffff' }">{{ inputText[2] }}</p>
    </div>
    <div class="barsBottom" :style="{'backgroundColor': colorPaletteFull[4].color}">
    </div>
  </div>
</template>

<style>
.wholePage{
  width: 75%;
  height: 90%;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: v-bind(chosenFonts[0]);
}
.bars{
  border-radius: 1vw 1vw 0 0;
  width: 100%;
  height: 9%;
}
.barsBottom{
  border-radius: 0 0 1vw 1vw;
  width: 100%;
  height: 9%;
}
.bigTitle{
  margin: 0;
  font-family: v-bind(chosenFonts[0]);
  font-size: v-bind(inputSize[0] + "vw");
}
.smallTitle{
  font-family: v-bind(chosenFonts[0]);
  font-size: v-bind(inputSize[1] + "vw");
  margin: 0;
}
.smallText{
  height: 9vw;
  font-size: v-bind(inputSize[2] + "vw");
  padding: 1vw;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: v-bind(chosenFonts[1]);
  display: flex;
  align-items: center;
}
.headArea{
  height: 41%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.descriptionArea{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 41%;
}
</style>